<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import ImgColorPicker from 'vue-img-color-picker';

export default {
  name: "Details",
  components: {ImgColorPicker},
  data: function () {
    return {
      ci: {
        cmyk: {c:0,m:0,y:0,k:0,c2:0,m2:0,y2:0,k2:0},
        logo: '',
        vector_logo: '',
        vector_logo_sw: '',
        websiteColor1: '',
        websiteColor2: ''
      },
      new_logo: null,
      new_vector_logo: null,
      new_vector_logo_sw: null,
    }
  },
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  created: function(){
    let self = this;
    Http.request('GET', '/user/company/ci').then((ci) => {
      self.ci = ci.data;
      if(!self.ci.cmyk){
        self.ci.cmyk = {c:0,m:0,y:0,k:0,c2:0,m2:0,y2:0,k2:0};
      }
    });
  },
  methods: {
    onLogoChange(e) {
      let self = this;
      let file = e.target.files[0];
      let imgPreviewUrl = URL.createObjectURL(file);
      let img = new Image();
      img.src = imgPreviewUrl;
      img.onload = function() {
        if (img.naturalWidth >= 400 && img.naturalHeight >= 400) {
          self.ci.logo = imgPreviewUrl;
        }
        else {
          self.ci.logo = '';
          self.new_logo = null;
          self.$bvToast.toast('Das Bild muss min. 400px breit und 400px hoch sein.', {
            title: 'Logo zu klein',
            variant: 'danger',
            autoHideDelay: 10000
          })
        }
      };
      return img;
    },
    onFileChange(e, field) {
      this[field] = e.target.files[0];
    },
    setWebsiteColor (color){
      this.ci.websiteColor1 = color.replace('#','');
    },
    setWebsiteColor2 (color){
      this.ci.websiteColor2 = color.replace('#','');
    },
    saveColor(){
      this.$bvModal.hide('logo-color-modal-1');
    },
    saveColor2(){
      this.$bvModal.hide('logo-color-modal-2');
    },
    save () {
      let self = this;
      let formData = new FormData();
      if(self.new_logo !== null){
        formData.append('new_logo', self.new_logo);
      }
      if(self.new_vector_logo !== null){
        formData.append('new_vector_logo', self.new_vector_logo);
      }
      if(self.new_vector_logo_sw !== null){
        formData.append('new_vector_logo_sw', self.new_vector_logo_sw);
      }
      formData.append('websiteColor1', self.ci.websiteColor1);
      formData.append('websiteColor2', self.ci.websiteColor2);
      formData.append('cmyk[c]', self.ci.cmyk.c);
      formData.append('cmyk[m]', self.ci.cmyk.m);
      formData.append('cmyk[y]', self.ci.cmyk.y);
      formData.append('cmyk[k]', self.ci.cmyk.k);
      formData.append('cmyk[c2]', self.ci.cmyk.c2);
      formData.append('cmyk[m2]', self.ci.cmyk.m2);
      formData.append('cmyk[y2]', self.ci.cmyk.y2);
      formData.append('cmyk[k2]', self.ci.cmyk.k2);

      Http.request('POST', '/user/company/ci', formData).then(function (response) {
        self.ci = response.data;
        self.new_logo = null;
        self.new_vector_logo = null;
        self.new_vector_logo_sw = null;

        self.$bvToast.toast('Die Änderungen wurden gespeichert', {
          title: 'Gespeichert',
          variant: 'success',
          autoHideDelay: 5000
        })
      })
    }
  }
}
</script>

<style scoped></style>
